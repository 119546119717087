import { useImperativeHandle, useState, forwardRef, memo, RefObject, useEffect } from "react";
import { createPortal } from "react-dom";
import styles from "./modal.module.scss";

interface ModalProps {
    trigger: string | React.ReactNode;
    title?: string | React.ReactNode;
    darkMode?: boolean;
    children: JSX.Element;
    visible?: boolean;
    _ref?: RefObject<any>
}

const Modal = forwardRef(({ trigger, title, darkMode, children, visible, _ref }: ModalProps) => {
    const [isVisible, setVisible] = useState(visible || false);

    const handleClick = () => {
        setVisible(!isVisible);
    }

    useImperativeHandle(_ref, () => ({
        isVisible: isVisible,
        setVisible: (visible: boolean) => {
            setVisible(visible);
        }
    }));

    useEffect(() => {
        setVisible(!!visible);
    }, [visible]);

    return (
        <>
            <span onClick={handleClick}>
                {trigger}
            </span>

            {isVisible && createPortal(
                <div className={styles.modal_container} onClick={() => setVisible(false)}>
                    <div className={`${styles.modal_content} ${darkMode && styles.darkMode}`} onClick={e => { e.stopPropagation(); }}>
                        {title && <div className={styles.modal_title}>
                            <div className={styles.title}>{title}</div>
                            <div><span className={styles.close} onClick={() => setVisible(false)}>x</span></div>
                        </div>}
                        {children}
                    </div>
                </div>,
                document.body
            )}
        </>
    );
});

Modal.displayName = 'Modal';

export default memo(Modal);
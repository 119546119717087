"use client";
import { useState } from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { Link, useParams } from "wouter";
import { adventurer_quest } from "../dto/interfaces";
import { fetcher } from "../utils/fetcher";
import { useUserStore } from "../stores/userStore";
import { set, useForm } from "react-hook-form";
import {
  createTreeMeasurementQuestDataItemSchema,
  CreateTreeMeasurementQuestDataItemSchemaType
} from "../admin/admin-components/CreateQuestForm/zod-objects";
import { zodResolver } from "@hookform/resolvers/zod";
import { getGeoCoordinates } from "../utils/geolocation";
import { questProperty } from "../utils/quest_data_utils";
import Header from "../components/Header/Header";
import styles from "./quest_data.module.scss";
import Button from "../components/Button/Button";
import Modal from "../components/Modal/Modal";

async function completeQuest(url: RequestInfo | URL, { arg }: { arg: any }) {
  await fetch(url, {
    method: "PATCH",
    body: JSON.stringify(arg)
  }).then((res) => res.json());
}

const QuestData = () => {
  const { authDetails } = useUserStore((state) => state);
  const [submitModal, setSubmitModal] = useState(false);
  const [submittingDataItem, setSubmittingDataItem] = useState(false);
  const [submitError, setSubmitError] = useState<boolean | string>(false);

  const username = authDetails?.getUsername();
  const params = useParams();
  // const fileInputRef = useRef<HTMLInputElement>(null);

  const {
    data: questData,
    error: questError,
    isLoading: questLoading
  } = useSWR<adventurer_quest>(
    username
      ? `${process.env.REACT_APP_API_URL}/adventurers/${username}/quests/${params.id}`
      : null,
    fetcher
  );

  const { data: uploadedFiles, mutate: refreshFiles } = useSWR(
    questData?.quest
      ? `${process.env.REACT_APP_API_URL}/adventurers/${username}/quests/${params.id}/data`
      : null,
    fetcher,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 404.
        if (error.status === 404) return;

        // Only retry up to 5 times.
        if (retryCount >= 5) return;
      }
    }
  );

  // Complete quest action
  const { trigger, isMutating } = useSWRMutation(
    `${process.env.REACT_APP_API_URL}/adventurers/${username}/quests/${questData?.quest_id}`,
    completeQuest /* options */
  );

  // init form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<CreateTreeMeasurementQuestDataItemSchemaType>({
    defaultValues: {
      image: undefined,
      additional_data: {
        tree_width: undefined,
        // tree_height: undefined,
        user_supplied_latitude: null,
        user_supplied_longitude: null
      }
    },
    resolver: zodResolver(createTreeMeasurementQuestDataItemSchema)
  });

  const onSubmit = async (
    data: CreateTreeMeasurementQuestDataItemSchemaType
  ) => {
    setSubmittingDataItem(true);

    const file = data.image[0];
    // @TODO: check for type of file?
    // <input> only accepts images though, so is redundant
    // this undefined check is also redundant since file is required by resolver
    if (!file) {
      return;
    }

    const geoCoordinates = await getGeoCoordinates().catch((error) => {
      // @TODO: do we want to still submit with no device gps? Probably no?
    });

    if (!geoCoordinates) {
      setSubmitError(
        "ERROR GETTING GEO COORDINATES: Geocordinates are required for each data point. Please close this browser window and open the app in a new window. When re-uploading your first data point in the window, make sure to allow geolocation when you are prompted. Alternatively, you can go to your browser settings and allow gps access for this site."
      );
      setSubmittingDataItem(false);
      return;
    }

    const formData = new FormData();

    // ADD VALUES TO FORMDATA OBJECT
    // needed to send image AND data
    //  @TODO CLEAN UP!!
    // (but also make variable per quest type, so plan for that)
    // data.additional_data.tree_height &&
    //   formData.append(
    //     "tree_height",
    //     data.additional_data.tree_height.toString()
    //   );
    data.additional_data.tree_width &&
      formData.append("tree_width", data.additional_data.tree_width.toString());
    geoCoordinates?.latitude &&
      formData.append("latitude", geoCoordinates.latitude.toString());
    geoCoordinates?.longitude &&
      formData.append("longitude", geoCoordinates.longitude.toString());
    data.additional_data.user_supplied_latitude &&
      formData.append(
        "user_supplied_latitude",
        data.additional_data.user_supplied_latitude.toString()
      );
    data.additional_data.user_supplied_longitude &&
      formData.append(
        "user_supplied_longitude",
        data.additional_data.user_supplied_longitude.toString()
      );

    file && formData.append("file", file);
    // END ADDING VALUES TO FORM DATA OBJECT

    // @TODO convert this into swr hook
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/adventurers/${username}/quests/${params.id}/data/uploads?data_type=image`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`
          },
          body: formData
        }
      );

      if (response.ok) {
        refreshFiles(); // Refresh the list of uploaded files
      } else {
        const responseJson = await response.json();
        setSubmitError(
          `FAILED TO UPLOAD DATA POINT. Error Response: ${response.status}, Error Details: ${responseJson?.detail}`
        );
      }
    } catch (error) {
      setSubmitError("FAILED TO UPLOAD DATA POINT. Error: " + error);
    }
    reset();
    setSubmittingDataItem(false);
  };

  if (questLoading) {
    return <div>Loading...</div>;
  }

  if (questError) {
    return <div>Error loading quest details</div>;
  }

  if (!questData) {
    return <div>No quest found</div>;
  }

  const deadline = questData.quest?.quest_deadline
    ? new Date(questData.quest?.quest_deadline).toLocaleDateString()
    : "None";

  return (
    <>
      <Header user={authDetails} />
      <main className={styles.container}>
        <div className={styles.quest_image_hero}>
          <div className={styles.questTitleContainer}>
            <div>
              <h1 style={{ margin: 0 }}>{questData.quest?.quest_name}</h1>
              <h2 style={{ display: "flex", columnGap: "48px" }}>
                <span>
                  {questData.quest?.locations &&
                    `${questData.quest?.locations[0].locality}, ${questData.quest?.locations[0].administrative_area}`}
                </span>{" "}
                <span>
                  {questData.quest?.quest_deadline ? `Due: ${deadline}` : ""}
                </span>
              </h2>
            </div>
          </div>
          <img src="/assets/images/quest-details-hero.jpg" alt="" />
        </div>
        {questData?.quest ? (
          <div className={styles.grid}>
            <div>
              <h2>Data Points</h2>
              {Array.isArray(uploadedFiles) && (
                <div>
                  {uploadedFiles.map((file: any, index: number) => {
                    // Extract the file name from the data_url
                    const fileName = file.data_url?.split("/").pop();

                    return (
                      <div className={styles.dataset} key={Math.random()}>
                        <h2>Data Point {index + 1}</h2>

                        <div className={styles.measurements}>
                          <div className={styles.properties}>
                            {file.additional_data &&
                              Object.entries(file.additional_data).map(
                                ([key, value]) => {
                                  if (key === "plant_id_data") {
                                    return;
                                  }

                                  const property = questProperty(key);

                                  return (
                                    <div key={Math.random()}>
                                      <h3>{property.label}</h3>
                                      <strong>
                                        {value as string | number}{" "}
                                        {property.units}
                                      </strong>
                                    </div>
                                  );
                                }
                              )}
                          </div>

                          <div className={styles.images}>
                            <h3>Images</h3>
                            <Modal
                              title={fileName}
                              trigger={
                                <img
                                  src={file.image_data_presigned_url}
                                  alt="data image"
                                  className={styles.thumbnail}
                                  style={{ width: "50px" }}
                                />
                              }>
                              <img
                                src={file.image_data_presigned_url}
                                alt="data image"
                                style={{ maxHeight: "87.5vh" }}
                              />
                            </Modal>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            {questData.status !== "completed" && (
              <div>
                <h2>Upload New Data Point</h2>

                <div className={styles.uploadContainer}>
                  <div>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      style={{ display: "flex", flexDirection: "column" }}>
                      <div className={styles.formItem}>
                        <label htmlFor="additional_data.tree_width">
                          Tree Circumference (
                          {questProperty("tree_width")?.units
                            ? "in " + questProperty("tree_width")?.units
                            : ""}
                          )
                        </label>

                        <input
                          type="number"
                          step="0.01"
                          className={styles.input}
                          {...register("additional_data.tree_width", {
                            setValueAs: (v) => {
                              return !!v ? parseFloat(v) : null;
                            }
                          })}
                        />
                        <p className={styles.formError}>
                          {errors.additional_data?.tree_width?.message}
                        </p>
                      </div>

                      {/* <div className={styles.formItem}>
                        <label htmlFor="additional_data.tree_height">
                          Tree Height (in cm)
                        </label>
                        <input
                          type="number"
                          step="0.01"
                          className={styles.input}
                          {...register("additional_data.tree_height", {
                            setValueAs: (v) => {
                              return !!v ? parseFloat(v) : null;
                            }
                          })}
                        />
                        <p className={styles.formError}>
                          {errors.additional_data?.tree_height?.message}
                        </p>
                      </div> */}

                      {/* <div className={styles.formItem}>
                        <label htmlFor="additional_data.user_supplied_latitude">
                          External Device Latitude
                        </label>
                        <input
                          type="number"
                          step="0.01"
                          className={styles.input}
                          {...register(
                            "additional_data.user_supplied_latitude",
                            {
                              setValueAs: (v) => {
                                return !!v ? parseFloat(v) : undefined;
                              }
                            }
                          )}
                        />
                        <p className={styles.formError}>
                          {
                            errors.additional_data?.user_supplied_latitude
                              ?.message
                          }
                        </p>
                      </div>

                      <div className={styles.formItem}>
                        <label htmlFor="additional_data.user_supplied_longitude">
                          External Device Longitude
                        </label>
                        <input
                          type="number"
                          step="0.01"
                          className={styles.input}
                          {...register(
                            "additional_data.user_supplied_longitude",
                            {
                              setValueAs: (v) => {
                                return !!v ? parseFloat(v) : undefined;
                              }
                            }
                          )}
                        />
                        <p className={styles.formError}>
                          {
                            errors.additional_data?.user_supplied_longitude
                              ?.message
                          }
                        </p>
                      </div> */}

                      <input
                        type="file"
                        id="image"
                        {...register("image")}
                        accept="image/*"
                        capture="environment"
                        // style={{ display: "none" }}
                      />

                      <p className={styles.formError}>
                        {errors.image?.message}
                      </p>

                      <div style={{ margin: "0 auto" }}>
                        <Button disabled={submittingDataItem}>
                          Add Data Point
                        </Button>
                      </div>

                      {submitError && (
                        <p className={styles.formError}>{submitError}</p>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            )}

            <div>
              <h2>Quest Status</h2>
              {questData.status === "completed" ? (
                <div className={styles.completedMessage}>
                  <h2>Congratulations!</h2>
                  <p>
                    You’ve completed your quest and successfully submitted your
                    data. Once your data is reviewed and meets our standards,
                    you will receive your compensation.
                  </p>

                  <p>
                    This quest is collecting data for X more days. Would you
                    like to embark on this quest again?
                  </p>

                  <Link href="/">
                    <Button variant="secondary">
                      I'm done with this quest
                    </Button>
                  </Link>
                </div>
              ) : (
                <>
                  <p>
                    If you are finished collecting data points, you can complete
                    the quest by clicking the button below.
                  </p>
                  <Modal
                    trigger={
                      <Button callback={() => setSubmitModal(true)}>
                        Complete Quest
                      </Button>
                    }
                    darkMode
                    visible={submitModal}>
                    <div style={{ padding: "3rem" }}>
                      {questData.status !== "conmpleted" ? (
                        <>
                          <p>Are you sure you're ready to submit your data?</p>
                          <div
                            style={{
                              display: "flex",
                              columnGap: "5rem",
                              justifyContent: "space-between",
                              marginTop: "5rem"
                            }}>
                            <Button
                              variant="secondary"
                              callback={() => setSubmitModal(false)}>
                              Go Back
                            </Button>
                            <Button
                              disabled={isMutating}
                              callback={async () => {
                                try {
                                  const result = await trigger(
                                    {
                                      ...questData,
                                      status: "completed"
                                    } /* options */
                                  );
                                } catch (e) {
                                  // error handling
                                }
                              }}>
                              Submit Data
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>Completed!</>
                      )}
                    </div>
                  </Modal>

                  <br />
                  <br />

                  <h3>Hey, wait... I'm still collectin' data points!</h3>
                  <p>
                    No problem! You can simply leave this area alone until
                    you're finished! Or, if you want to go back to the quest
                    details page,{" "}
                    <Link href={`/quests/${params.id}`}>click here!</Link>
                  </p>
                </>
              )}
            </div>
          </div>
        ) : (
          <div>You are not signed up for this quest.</div>
        )}
      </main>
    </>
  );
};

export default QuestData;

import styles from "./card.module.scss";

interface CardContentProps {
  children: React.ReactNode;
}

export default function CardContent({ children }: CardContentProps) {
  return (
    <div
      className={styles.cardContent}
    >
      {children}
    </div>
  );
}

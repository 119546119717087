import { Suspense, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import useSWR from "swr";
import { Link } from "wouter";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Hub } from "aws-amplify";
import { BsArrowRight } from "react-icons/bs";
import { FaUsersGear } from "react-icons/fa6";
import { FiUser } from "react-icons/fi";
import { FiCheckSquare } from "react-icons/fi";
import { adventurer_quest } from "../../dto/interfaces";
import { fetcher } from "../../utils/fetcher";
import { fetchUserData, handleSignIn, handleSignOut } from "../../utils/userAuth";
import { useUserStore } from "../../stores/userStore";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import { userLinks, betaFeatureLinks, adminLinks } from "./header-link-data";
import styles from "./header.module.scss";
import { BiCamera } from "react-icons/bi";
import { Card } from "../Card/Card";
import CardContent from "../Card/CardContent";
import CardFooter from "../Card/CardFooter";
import CardHeader from "../Card/CardHeader";

interface HeaderProps {
  user: CognitoUser | null;
  showQuests?: boolean;
  showLogo?: boolean;
  fullWidth?: boolean;
}

export default function Header({ user, showQuests, fullWidth = false, showLogo = true }: HeaderProps) {
  const userStore = useUserStore((state: { authDetails: any; }) =>
    state.authDetails);
  const { isAdmin, isLoggedIn, isFeatureTester } = useUserStore((state) =>
    state);
  const [showCompletedQuests, setShowCompletedQuests] = useState(false);

  const adminSection = window.location.href.indexOf("admin") !== -1;

  const { data: subscribedQuests, isLoading, error } = useSWR<adventurer_quest[]>(
    showQuests && userStore && userStore.username
      ? `${process.env.REACT_APP_API_URL}/adventurers/${userStore.username}/quests`
      : null,
    fetcher
  );

  useEffect(() => {
    const listener = (data: any) => {
      switch (data.payload.event) {
        case 'signIn':
          fetchUserData();
          break;
        default:
          break;
      }
    };

    Hub.listen('auth', listener);

    return () => {
      Hub.remove('auth', listener);
    };
  }, []);

  return (
    <div className={styles.pageheaderBackground}>
      <header className={styles['pageheader-container']} style={{ maxWidth: fullWidth ? '100%' : '' }}>
        {!!showLogo && <div>
          <Link href="/">
            <img
              src="/assets/nobos-logo.svg"
              className={styles['nobos-logo']}
              alt="Nobos logo"
            />
          </Link>
        </div>}

        <nav className={styles['pageheader-nav']}>
          {userLinks.map(
            (link, i) =>
              !link.restricted?.(user) && isLoggedIn && (
                <Link
                  key={i}
                  href={link.href}
                  className={`${styles['nav-item']}`}
                  aria-label={link.ariaLabel}
                >
                  {link.displayText}
                </Link>
              )
          )}

          {adminSection && adminLinks.map(
            (link, i) =>
              !link.restricted?.(user) && isLoggedIn && (
                <Link
                  key={i}
                  href={link.href}
                  className={`${styles['nav-item']}`}
                  aria-label={link.ariaLabel}
                >
                  {link.displayText}
                </Link>
              )
          )}
        </nav>

        {isFeatureTester && <div>
          <Dropdown
            title={<FaUsersGear color="#000" size={20} />}
            orientation="right"
          >
            <>
              {betaFeatureLinks.map(
                (link, i) =>
                  !link.restricted?.(user) && (
                    <Link
                      key={i}
                      href={link.href}
                      className={styles["menu-item"]}
                      aria-label={link.ariaLabel}
                    >
                      {link.displayText}
                    </Link>
                  )
              )}
            </>
          </Dropdown>
        </div>}

        <div className="account-container">
          <Dropdown
            title={<FiUser color="#000" size={20} />}
            orientation="right"
          >
            <div style={{ marginTop: '-12px', marginBottom: '-12px' }}>
              {/* {isLoggedIn && (
                <Link href="/profile" className={styles["menu-item"]}>
                  My Profile
                </Link>
              )} */}

              {isLoggedIn && isAdmin && (
                <Link href="/admin_dashboard" className={styles["menu-item"]}>
                  Admin
                </Link>
              )}

              {isLoggedIn && (
                <span onClick={e => handleSignOut()} className={styles["menu-item"]}>
                  Sign out
                </span>
              )}

              {!isLoggedIn && (
                <span onClick={handleSignIn} className={styles["menu-item"]}>
                  Log In
                </span>
              )}
            </div>
          </Dropdown>
        </div>
      </header>

      {showQuests && <div className={`${styles.headerContent} ${!isLoggedIn ? styles.headerContentPlain : ''}`}>
        {!isLoggedIn ? <>
          <h1>High Stakes Data Collection</h1>

          <p>Paragraph about Nobos and how it works. Maybe a slideshow with splash screen like we have in the mobile app.</p>

          <Button callback={handleSignIn}>Sign Up <BsArrowRight /></Button>
        </> : <>
          <ErrorBoundary
            fallback={
              <p>There was an error while attempting to fetch your quests.</p>
            }
          >
            <Suspense fallback={<div>Loading quests...</div>}>
              <h1 className={styles.welcome}>Welcome back.</h1>

              <div style={{ marginBottom: '6px' }}>
                <h2 className={styles.continue}>Continue Your Quests</h2>
                <input type="checkbox" name="hide-quests" onClick={e => setShowCompletedQuests(e.currentTarget.checked)} /> <label htmlFor="hide-quests">Hide completed quests</label>
              </div>

              <div className={styles.my_quests}>
                {subscribedQuests &&
                  subscribedQuests.map(
                    (subscribedQuest: adventurer_quest) =>
                      subscribedQuest.quest && (!showCompletedQuests || subscribedQuest.status !== "completed") &&
                      <Card key={Math.random()} image={subscribedQuest.quest.quest_image && subscribedQuest.quest.quest_image} imageContent={subscribedQuest.status === "completed" && <div className={`${styles.pill} ${styles.pillSmall} ${styles.statusPill}`}><FiCheckSquare alignmentBaseline="baseline" /> completed</div>} orientation='row' imageHeight='94px' imageWidth='94px'>
                        <CardHeader><Link href={`/quests/${subscribedQuest.quest.id}`}>{subscribedQuest.quest.quest_name}</Link></CardHeader>
                        <CardContent>
                          <div style={{ display: 'flex', alignItems: 'flex-end', columnGap: '6px', justifyContent: 'space-between' }}>
                            <div>{subscribedQuest.quest.locations && `${subscribedQuest.quest.locations[0].locality}, ${subscribedQuest.quest.locations[0].administrative_area}`}</div> <div>{subscribedQuest.quest.quest_deadline ? new Date(subscribedQuest.quest.quest_deadline).toLocaleDateString() : 'none'}</div>
                          </div>
                        </CardContent>
                        <CardFooter>
                          <>
                            <div style={{ display: 'flex', alignItems: 'center', columnGap: '6px' }}>
                              <BiCamera size={24} /> <span>Photography</span>
                            </div>
                          </>
                        </CardFooter>
                      </Card>
                  )}
              </div>
            </Suspense>
          </ErrorBoundary>
        </>
        }
      </div>}
    </div >
  );
}

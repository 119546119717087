import { z } from "zod";
import { Prisma } from "@prisma/client";

/////////////////////////////////////////
// HELPER FUNCTIONS
/////////////////////////////////////////

// JSON
//------------------------------------------------------

// export type NullableJsonInput = Prisma.JsonValue | null | 'JsonNull' | 'DbNull' | Prisma.NullTypes.DbNull | Prisma.NullTypes.JsonNull;

// export const transformJsonNull = (v?: NullableJsonInput) => {
//   if (!v || v === 'DbNull') return Prisma.DbNull;
//   if (v === 'JsonNull') return Prisma.JsonNull;
//   return v;
// };

// export const JsonValueSchema: z.ZodType<Prisma.JsonValue> = z.lazy(() =>
//   z.union([
//     z.string(),
//     z.number(),
//     z.boolean(),
//     z.literal(null),
//     z.record(z.lazy(() => JsonValueSchema.optional())),
//     z.array(z.lazy(() => JsonValueSchema)),
//   ])
// );

// export type JsonValueType = z.infer<typeof JsonValueSchema>;

// export const NullableJsonValue = z
//   .union([JsonValueSchema, z.literal('DbNull'), z.literal('JsonNull')])
//   .nullable()
//   .transform((v) => transformJsonNull(v));

// export type NullableJsonValueType = z.infer<typeof NullableJsonValue>;

// export const InputJsonValueSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
//   z.union([
//     z.string(),
//     z.number(),
//     z.boolean(),
//     z.object({ toJSON: z.function(z.tuple([]), z.any()) }),
//     z.record(z.lazy(() => z.union([InputJsonValueSchema, z.literal(null)]))),
//     z.array(z.lazy(() => z.union([InputJsonValueSchema, z.literal(null)]))),
//   ])
// );

// export type InputJsonValueType = z.infer<typeof InputJsonValueSchema>;

/////////////////////////////////////////
// ENUMS
/////////////////////////////////////////

export const TransactionIsolationLevelSchema = z.enum([
  "ReadUncommitted",
  "ReadCommitted",
  "RepeatableRead",
  "Serializable"
]);

export const AdventurerScalarFieldEnumSchema = z.enum([
  "id",
  "bio",
  "profile_picture_url"
]);

export const OrganizationScalarFieldEnumSchema = z.enum([
  "id",
  "organization_name",
  "organization_email"
]);

export const QuestScalarFieldEnumSchema = z.enum([
  "id",
  "quest_name",
  "quest_image",
  "organization_id",
  "quest_description",
  "quest_difficulty",
  "study_motivation",
  "training_requirement",
  "safety",
  "equipment",
  "quest_deadline",
  "adventurer_limit",
  "quest_status",
  "quest_type"
]);

export const Adventurer_questScalarFieldEnumSchema = z.enum([
  "adventurer_id",
  "quest_id",
  "status"
]);

export const Quest_dataScalarFieldEnumSchema = z.enum([
  "id",
  "data_url",
  "data_type",
  "coordinates",
  "adventurer_id",
  "quest_id",
  "additional_data"
]);

export const LocationScalarFieldEnumSchema = z.enum([
  "id",
  "quest_id",
  "address_line1",
  "address_line2",
  "locality",
  "administrative_area",
  "postal_code",
  "coordinates",
  "country"
]);

export const Feature_testing_test_gps_bad_dataScalarFieldEnumSchema = z.enum([
  "id",
  "coordinates",
  "highAccuracySolved",
  "sameAsNative",
  "location",
  "details"
]);

export const SortOrderSchema = z.enum(["asc", "desc"]);

// export const NullableJsonNullValueInputSchema = z
//   .enum(["DbNull", "JsonNull"])
//   .transform((value) =>
//     value === "JsonNull"
//       ? Prisma.JsonNull
//       : value === "DbNull"
//         ? Prisma.DbNull
//         : value
//   );

export const QueryModeSchema = z.enum(["default", "insensitive"]);

export const NullsOrderSchema = z.enum(["first", "last"]);

// export const JsonNullValueFilterSchema = z
//   .enum(["DbNull", "JsonNull", "AnyNull"])
//   .transform((value) =>
//     value === "JsonNull"
//       ? Prisma.JsonNull
//       : value === "DbNull"
//         ? Prisma.JsonNull
//         : value === "AnyNull"
//           ? Prisma.AnyNull
//           : value
//   );

export const Quest_StatusSchema = z.enum([
  "INACTIVE",
  "ACCEPTING_APPLICATIONS",
  "IN_PROGRESS",
  "DATA_PROCESSING",
  "COMPLETED"
]);

export type Quest_StatusType = `${z.infer<typeof Quest_StatusSchema>}`;

export const Quest_TypeSchema = z.enum([
  "UNCATEGORIZED",
  "TREES",
  "WATER",
  "SOIL",
  "AIR",
  "HIKING",
  "DIVING",
  "PADDLING",
  "BOATING"
]);

export type Quest_TypeType = `${z.infer<typeof Quest_TypeSchema>}`;

export const Quest_DifficultySchema = z.enum([
  "EASY",
  "MILD",
  "MEDIUM",
  "HARD",
  "VERY_HARD"
]);

export type Quest_DifficultyType = `${z.infer<typeof Quest_DifficultySchema>}`;

/////////////////////////////////////////
// MODELS
/////////////////////////////////////////

/////////////////////////////////////////
// ADVENTURER SCHEMA
/////////////////////////////////////////

export const adventurerSchema = z.object({
  id: z.string(),
  bio: z.string().nullable(),
  profile_picture_url: z.string().nullable()
});

export type adventurer = z.infer<typeof adventurerSchema>;

/////////////////////////////////////////
// ORGANIZATION SCHEMA
/////////////////////////////////////////

export const organizationSchema = z.object({
  id: z.number().int(),
  organization_name: z.string(),
  organization_email: z.string()
});

export type organization = z.infer<typeof organizationSchema>;

/////////////////////////////////////////
// QUEST SCHEMA
/////////////////////////////////////////

export const questSchema = z.object({
  quest_status: Quest_StatusSchema,
  quest_type: Quest_TypeSchema,
  id: z.number().int(),
  quest_name: z.string(),
  quest_image: z.string().nullable(),
  organization_id: z.number().int(),
  quest_description: z.string().nullable(),
  quest_difficulty: z.number().int(),
  study_motivation: z.string().nullable(),
  training_requirement: z.string().nullable(),
  safety: z.string().nullable(),
  equipment: z.string().nullable(),
  quest_deadline: z.date().nullable(),
  adventurer_limit: z.number().int().nullable()
});

export type quest = z.infer<typeof questSchema>;

/////////////////////////////////////////
// ADVENTURER QUEST SCHEMA
/////////////////////////////////////////

export const adventurer_questSchema = z.object({
  adventurer_id: z.string(),
  quest_id: z.number().int(),
  status: z.string()
});

export type adventurer_quest = z.infer<typeof adventurer_questSchema>;

/////////////////////////////////////////
// QUEST DATA SCHEMA
/////////////////////////////////////////
// JSON SCHEMA - https://zod.dev/?id=json-type
const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
type Literal = z.infer<typeof literalSchema>;
type Json = Literal | { [key: string]: Json } | Json[];
const jsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)])
);

export const quest_dataSchema = z.object({
  id: z.number().int(),
  data_url: z.string().nullable(),
  data_type: z.string(),
  coordinates: z.string().nullable(),
  adventurer_id: z.string().nullable(),
  quest_id: z.number().int().nullable(),
  // additional_data: JsonValueSchema.nullable(),
  additional_data: jsonSchema.nullable()
});

export type quest_data = z.infer<typeof quest_dataSchema>;

/////////////////////////////////////////
// LOCATION SCHEMA
/////////////////////////////////////////

export const locationSchema = z.object({
  id: z.number().int(),
  quest_id: z.number().int(),
  address_line1: z.string().nullable(),
  address_line2: z.string().nullable(),
  locality: z.string(),
  administrative_area: z.string(),
  postal_code: z.string(),
  coordinates: z.number().array(),
  country: z.string()
});

export type location = z.infer<typeof locationSchema>;

/////////////////////////////////////////
// FEATURE TESTING TEST GPS BAD DATA SCHEMA
/////////////////////////////////////////

export const feature_testing_test_gps_bad_dataSchema = z.object({
  id: z.number().int(),
  coordinates: z.number().array(),
  highAccuracySolved: z.boolean(),
  sameAsNative: z.boolean(),
  location: z.string(),
  details: z.string().nullable()
});

export type feature_testing_test_gps_bad_data = z.infer<
  typeof feature_testing_test_gps_bad_dataSchema
>;

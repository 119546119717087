import { quest } from "../../dto/interfaces";
import styles from "./adminHome.module.css";
import CreateQuestForm from "../admin-components/CreateQuestForm/create-quest-form";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import useQuest from "../../utils/hooks/use_quest";
import Header from '../../components/Header/Header';
import { useUserStore } from "../../stores/userStore";
import { Link } from "wouter";

export default function AdminDashboardIndex() {
  const {
    authDetails,
    isAdmin
  } = useUserStore((state) => state);

  const { quests } = useQuest({ admin: true });

  const getQuestStatus = (quest_deadline: Date) => {
    const now = new Date().getTime();
    const questDeadline = new Date(quest_deadline).getTime();
    if (questDeadline < now) {
      return "expired";
    }
    return "active";
  };
  return (
    <div className={styles.container}>
      {!isAdmin ? <p>You're not authorized to view this page.</p> : <>
        <Header user={authDetails} showQuests={false} fullWidth />
        <div className={styles.quests}>
          <div className={styles.titleContainer}>
            <h1>Quests</h1>
            <Modal
              trigger={<Button>Create New Quest</Button>}
              title="Create New Quest"
            >
              <CreateQuestForm />
            </Modal>
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Quest</th>
                  <th scope="col">Organization</th>
                  <th scope="col">location</th>
                  <th scope="col">deadline</th>
                  <th scope="col">status</th>
                  <th scope="col">samples for review</th>
                  <th scope="col">pending applications</th>
                  <th scope="col">progress</th>
                </tr>
              </thead>
              {Array.isArray(quests) && (
                <tbody>
                  {quests.map((quest: quest) => (
                    <tr key={quest.id}>
                      <td>
                        <Modal
                          trigger={<Button>view/edit</Button>}
                          title="View/Edit Quest"
                        >
                          <CreateQuestForm quest={quest} />
                        </Modal>
                      </td>
                      <td>{quest.quest_name}</td>
                      <td>{quest.organization?.organization_name}</td>
                      <td>{`${quest.locations?.[0]?.locality || ""}, ${quest.locations?.[0]?.country || ""
                        }`}</td>
                      {quest.quest_deadline ? (
                        <td>{new Date(quest.quest_deadline).toDateString()}</td>
                      ) : (
                        <td>n/a</td>
                      )}
                      <td>{quest.quest_status}</td>
                      <td>
                        <Link href="/admin_dashboard/samples">
                          {quest.quest_data?.length ?? 0} Samples
                        </Link>
                      </td>
                      <td>pending_applications</td>
                      <td>{quest.quest_status}</td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>

        <div className={styles.bottomThird}>
          <div className={styles.adventurers}>
            <h1>Adventurers</h1>
          </div>
          <div className={styles.datasets}>
            <h1>Datasets</h1>
          </div>
        </div>
      </>}
    </div>
  );
}

interface ExtendedError extends Error {
  info?: any;
  status?: number;
}

export const fetcher = async (url: RequestInfo | URL) => {
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  });

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.');
    // Attach extra info to the error object.
    //   error.info = await res.json()
    //   error.status = res.status
    throw error;
  }

  return res.json();
};

export const postFetcher = async (url: RequestInfo | URL, arg: any) => {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(arg)
  });

  if (!res.ok) {
    const error = new Error(
      'An error occurred while fetching the data.'
    ) as ExtendedError;
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

export const putFetcher = async (url: RequestInfo | URL, arg: any) => {
  const res = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(arg)
  });

  // pulled from swr docs
  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error(
      'An error occurred while fetching the data.'
    ) as ExtendedError;
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

type FetcherMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export const universalFetcher = async (
  url: RequestInfo | URL,
  arg: any,
  fetcherMethod: FetcherMethod
) => {
  const options: {
    method: FetcherMethod;
    headers: { Authorization: string; 'Content-Type': string };
    body?: string;
  } = {
    method: fetcherMethod,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      'Content-Type': 'application/json'
    }
  };
  if (fetcherMethod !== 'GET') {
    options.body = JSON.stringify(arg);
  }
  const res = await fetch(url, options).catch((error) => {
    console.log(error);
  });

  if (!res?.ok) {
    const error = new Error(
      'An error occurred while fetching the data.'
    ) as ExtendedError;
    // Attach extra info to the error object.
    error.info = await res?.json();
    error.status = res?.status;
    throw error;
  }
  return res.json();
};

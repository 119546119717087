import CardHeader from "./CardHeader";
import CardContent from "./CardContent";
import CardFooter from "./CardFooter";
import styles from "./card.module.scss";

interface CardProps {
  image?: string | null;
  imageContent?: React.ReactNode;
  imageWidth?: string;
  imageHeight?: string;
  orientation?: string;
  children: React.ReactNode;
  callback?: () => void;
}

function Card({
  image,
  imageContent,
  imageWidth = "215px",
  imageHeight = "130px",
  orientation,
  children,
  callback
}: CardProps) {
  const handleClick = () => {
    callback && callback();
  };

  return (
    <article
      className={`${styles.card} ${orientation && orientation === "row" && styles.row}`}
      onClick={() => handleClick()}>
      {image && (
        <div
          className={styles.cardImage}
          style={{
            backgroundImage: `url(${image})`,
            width: imageWidth,
            height: imageHeight
          }}>
          {imageContent}
        </div>
      )}

      <div>{children}</div>
    </article>
  );
}

export { Card, CardHeader, CardContent, CardFooter };

import styles from "./card.module.scss";

interface CardFooterProps {
  children: React.ReactNode;
}

export default function CardFooter({ children }: CardFooterProps) {
  return (
    <footer
      className={styles.cardFooter}
    >
      {children}
    </footer>
  );
}

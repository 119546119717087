"use client";
import { Suspense, useEffect, useRef, useState } from "react";
import useSWR from "swr";
import { Link, useParams } from "wouter";
import Markdown from "react-markdown";
import { FaArrowRightLong, FaWifi, FaTape } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import { Map, ZoomControl, Marker, Overlay, Point } from "pigeon-maps";
import { quest, adventurer_quest } from "../dto/interfaces";
import Button from "../components/Button/Button";
import Modal from "../components/Modal/Modal";
import { fetcher } from "../utils/fetcher";
import { questTypeSelector } from "../utils/quest_type_selector";
import { Auth } from "aws-amplify";
import { useUserStore } from "../stores/userStore";
import Header from "../components/Header/Header";
import styles from "./quest_details.module.scss";
import { IoMdShare } from "react-icons/io";
// import ApplyToQuestForm from "../components/ApplyToQuestForm";
import { questEquipmentSelector } from "../utils/quest_equipment_selector";

const QuestDetails = () => {
  const { authDetails } = useUserStore((state) => state);

  const username = authDetails?.getUsername();
  const params = useParams();
  // const fileInputRef = useRef<HTMLInputElement>(null);
  const BeingQuestModalRef = useRef<any>();
  const [isSignUpSuccessful, setSignUpSuccessful] = useState(false);

  const {
    data: adventurerQuestData,
    error: adventurerQuestError,
    isLoading: adventurerQuestLoading
  } = useSWR<adventurer_quest>(
    username
      ? `${process.env.REACT_APP_API_URL}/adventurers/${username}/quests/${params.id}`
      : null,
    fetcher
  );

  const {
    data: questData,
    error,
    isLoading
  } = useSWR<quest>(
    !adventurerQuestData?.quest_id
      ? `${process.env.REACT_APP_API_URL}/quests/${params.id}`
      : null,
    fetcher
  );

  const quest: quest | undefined = adventurerQuestData?.quest
    ? adventurerQuestData.quest
    : questData;

  const initialAnchor: Point = quest?.locations
    ? [quest?.locations[0].coordinates[0], quest?.locations[0].coordinates[1]]
    : [0, 0];
  const [center, setCenter] = useState<[number, number]>(initialAnchor);
  const [zoom, setZoom] = useState(4);
  const [overlay, setOverlay] = useState({
    anchor: initialAnchor,
    quest: quest
  });
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  useEffect(() => {
    setCenter(initialAnchor);
  }, [quest]);

  const handleMarker = (e: { event?: Event; anchor?: Point }, quest: any) => {
    setOverlay({
      anchor: initialAnchor,
      quest: quest
    });
    setIsOverlayVisible(!isOverlayVisible);
  };

  const closeQuestModal = () => {
    BeingQuestModalRef?.current?.setVisible(false);
  };

  const beginQuest = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/adventurers/${username}/quests/${params.id}/sign-up`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`
          }
        }
      );

      if (response.ok) {
        setSignUpSuccessful(true);
        closeQuestModal();
      } else {
        console.error("Failed to sign up for the quest");
      }
    } catch (error) {
      console.error("Error signing up for the quest:", error);
    }
  };

  async function handleSignIn() {
    try {
      await Auth.federatedSignIn();
    } catch (error) {
      console.log("Error signing in", error);
    }
  }

  if (isLoading || adventurerQuestLoading) {
    return <div>Loading...</div>;
  }

  if (error || adventurerQuestError) {
    return <div>Error loading quest details</div>;
  }

  if (!quest) {
    return <div>No quest found</div>;
  }

  const deadline = quest.quest_deadline
    ? new Date(quest.quest_deadline).toLocaleDateString()
    : "None";

  const difficultyRating = [];
  for (let i = 0; i < 5; i++)
    difficultyRating.push(
      <div
        className={`${styles.difficultyMarker} ${i < quest.quest_difficulty ? styles["is-selected"] : ""}`}
      />
    );

  type QuestDetailsDropdownProps = {
    title: string;
    children?: React.ReactNode;
    content?: string | null;
  };
  const QuestDetailsDropdown = ({
    title,
    content = "content not available",
    children = <div>content not available</div>
  }: QuestDetailsDropdownProps) => {
    const [showContent, setShowContent] = useState(false);
    return (
      <div className={styles.quest_details_dropdown}>
        <div className={styles.default_shown_container}>
          <h3>{title}</h3>
          <div
            onClick={() => setShowContent(!showContent)}
            style={{ cursor: "pointer" }}>
            <FaAngleRight size={26} />
          </div>
        </div>
        {showContent && (
          <div className={styles.dropdown_content}>{children}</div>
        )}
      </div>
    );
  };

  return (
    <>
      <Header user={authDetails} />
      <main>
        <div className={styles.quest_image_hero}>
          <div className={styles.questTitleContainer}>
            <div>
              <h1
                style={{
                  margin: 0,
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  color: "white"
                }}>
                {quest.quest_name}
              </h1>
              {/* <h2 style={{ display: "flex", columnGap: "48px" }}>
                <span>
                  {quest.locations &&
                    `${quest.locations[0].locality}, ${quest.locations[0].administrative_area}`}
                </span>{" "}
                <span>{quest.quest_deadline ? `Due: ${deadline}` : ""}</span>
              </h2> */}
            </div>

            <div>
              {!username && (
                <div>
                  <span
                    onClick={handleSignIn}
                    style={{
                      textDecoration: "underline",
                      color: "blue",
                      cursor: "pointer"
                    }}>
                    Sign up
                  </span>{" "}
                  to go on an adventure!
                </div>
              )}

              {username && !adventurerQuestData?.quest ? (
                <Modal
                  trigger={
                    <Button>
                      Apply to Quest <FaArrowRightLong size={14} />
                    </Button>
                  }
                  title="Sign up for this quest"
                  _ref={BeingQuestModalRef}>
                  {/* <ApplyToQuestForm quest={quest} /> */}
                  <>
                    <p>
                      Please confirm you meet all the location and gear
                      requirements for the quest:{" "}
                      <strong>{quest.quest_name}</strong>.
                    </p>
                    <Button callback={beginQuest}>Yes, sign me up!</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button callback={closeQuestModal}>Actually, no...</Button>
                  </>
                </Modal>
              ) : (
                <Link href={`/quests/${params.id}/data`}>
                  <Button>
                    Submit Data <FaArrowRightLong size={14} />
                  </Button>
                </Link>
              )}
            </div>
          </div>
          <img src="/assets/images/quest-details-hero.jpg" alt="" />
        </div>

        {/* subhead info row */}
        <aside className={styles.questInfo}>
          <div>
            <h2>Adventure Type</h2>
            <img
              src={questTypeSelector("HIKING")}
              alt={"HIKING"}
              // src={questTypeSelector(quest.quest_type?.adventure_type)}
              // alt={quest.quest_type?.name}
              className={styles.questTypeIcon}
            />
            <span
              className={styles.questType}
              style={{ textTransform: "capitalize" }}>
              hiking
            </span>
            {/* {quest.quest_subtype?.length &&
              quest.quest_subtype.map((st, i) => {
                return (
                  <span
                    key={i}
                    className={styles.questType}
                    style={{ textTransform: "capitalize" }}>
                    {st.adventure_subtype.toLowerCase()}
                  </span>
                );
              })} */}
            {/* <span className={styles.questType}>{quest.quest_type?.name}</span> */}
          </div>

          <div>
            <h2>Terrain</h2>
            <span className={styles.questType}>
              FORESTS, OUTDOOR TRAILS
              {/* {quest.quest_type!.adventure_type} */}
            </span>
          </div>

          <div>
            <h2>Equipment Needed</h2>
            <>
              {["PHONE", "GPS"].map((eq, i) => {
                return (
                  <img
                    key={i}
                    src={questEquipmentSelector(eq)}
                    alt={eq}
                    className={styles.questTypeIcon}
                  />
                );
              })}
              <span className={styles.questTypeIcon}>
                <FaWifi />
              </span>
              <span className={styles.questTypeIcon}>
                <FaTape />
              </span>
            </>
          </div>

          <div>
            <h2>Data Collection Type</h2>
            <span
              className={styles.questType}
              style={{ textTransform: "capitalize" }}>
              Photography
              {/* {quest.collection_type.replace("_", " ").toLowerCase()} */}
            </span>
          </div>
          <div>
            <h2>Expected Duration</h2>
            <span className={styles.questType}>5-7 minutes/sample</span>
          </div>

          <div>
            <h2>Compensation</h2>
            <span className={styles.questType}>None (volunteer basis)</span>
          </div>
        </aside>

        <div className={styles.container}>
          <Suspense fallback={<div>Loading quest...</div>}>
            {/* share button */}
            <div className={`${styles.content} ${styles.share_row}`}>
              <Button variant="icon_pill">
                <>
                  <IoMdShare /> SHARE
                </>
              </Button>
            </div>

            {/* row 1 */}
            <div className={styles.content}>
              <div className={styles.overview}>
                {isSignUpSuccessful && (
                  <div className={styles.signup_success}>
                    You have successfully signed up for the quest:{" "}
                    <strong>{quest.quest_name}!</strong>
                  </div>
                )}

                <div className={styles.row_1_left}>
                  <h4>LOCATION</h4>
                  <h5 className={styles.cell_content}>
                    San Francisco, California,
                    <br />
                    Berkeley, California,
                    <br />
                    Austin, Texas,
                    <br />
                    Talent, Oregon,
                    <br />
                    and Asia
                  </h5>
                  {/* <h5 className={styles.cell_content}>
                    {quest.locations?.length
                      ? `${quest.locations[0].locality}, ${quest.locations[0].administrative_area}`
                      : "n/a"}
                  </h5> */}

                  <h4>DATE</h4>
                  {/* <h5 className={styles.cell_content}>TBD-{deadline}</h5> */}
                  <h5 className={styles.cell_content}>
                    October 24th - <br />
                    November 6th, 2024
                  </h5>
                  <h4>STUDY PARTNER</h4>
                  <h5 className={styles.cell_content}>University of Zurich</h5>
                </div>

                {/* <h2>Overview</h2>
                <Markdown>{quest.quest_description}</Markdown> */}
              </div>

              <div className={styles.row_1_right}>
                <div className={styles.cell_content}>
                  <h3>Overview</h3>
                  <div>{quest.quest_description}</div>
                </div>
                <div className={styles.cell_content}>
                  <h3>Study Motivation</h3>
                  {/* <div>{quest.study_motivation}</div> */}
                  <div>
                    This quest aims to gather critical tree biodiversity data to
                    aid the University of Zurich's{" "}
                    <a
                      href="https://3dforecotech.eu/activities/tree-species-annotations-and-aerial-rgb-or-rgbi-imagery/"
                      target="_blank"
                      rel="noreferrer">
                      ongoing research
                    </a>
                    , improving the scientific understanding of trees worldwide,
                    particularly beyond Europe. By contributing data from
                    diverse environments, participants help shape more accurate
                    global predictions about tree species distribution and
                    biodiversity conservation.
                  </div>
                </div>
                <div className={styles.cell_content}>
                  <h3>The Task</h3>
                  <div>
                    Collect and submit pictures of tree bark with circumference
                    measurements from at least 20 different trees. Data will be
                    collected using the Nobos app, which will automatically
                    gather geolocation data during picture uploads. The process
                    involves traveling to a forest or park, measuring tree
                    circumferences, and submitting the photos and measurements
                    through the app.
                  </div>
                  {/* <div>{quest.location_description}</div> */}
                </div>
              </div>
            </div>

            {/* DESIGN VERSION 2 */}
            {/* <div className={styles.safety_information}>
                <div>
                  <h3 className={styles.subsectionTitle}>Adventure Type</h3>
                  <img
                    src={questTypeSelector(quest.quest_type?.adventure_type)}
                    alt={quest.quest_type?.name}
                    className={styles.questTypeIcon}
                  />
                  <span className={styles.questType}>
                    {quest.quest_type?.name}
                  </span>
                </div>
                <div>
                  <h3 className={styles.subsectionTitle}>
                    Level of Difficulty
                  </h3>
                  <div className={styles.difficultyMarkerContainer}>
                    {difficultyRating}
                  </div>
                </div>
                <div>
                  <h3 className={styles.subsectionTitle}>Equipment (see full list below)</h3>
                </div>
              </div> */}

            {/* <div className={styles.risk_management}>
                <h3>Risk Management</h3>
                <Markdown>{quest.safety}</Markdown>
              </div> */}

            {/* <div className={styles.plusBackground}></div> */}

            {/* row 2 - location details*/}
            <div className={`${styles.content} ${styles.row_2}`}>
              <h2>More About the Location</h2>
              <div>
                Participants can collect data in any forest or outdoor location,
                with current testers based in North America and Asia.
              </div>
              <div>
                {/* {quest.location_description ||
                  "No location description available."} */}
              </div>
            </div>

            {/* row 3 - map and images */}
            <div className={`${styles.content} ${styles.map_row_container}`}>
              <div className={styles.map_container}>
                <Map
                  center={center}
                  zoom={zoom}
                  onBoundsChanged={({ center, zoom }) => {
                    setCenter(center);
                    setZoom(zoom);
                  }}>
                  <ZoomControl />

                  {/* SF */}
                  <Marker
                    width={50}
                    // anchor={[
                    //   quest.locations ? quest.locations[0].coordinates[0] : 0,
                    //   quest.locations ? quest.locations[0].coordinates[1] : 0
                    // ]}

                    // HARD CODE FOR NOW
                    anchor={[37.771946867043106, -122.45409253423233]}
                    key={Math.random()}
                    onClick={(e) => handleMarker(e, quest)}
                  />

                  {/* BERKELEY */}
                  <Marker
                    width={50}
                    anchor={[37.87127326386391, -122.27311711851547]}
                    key={Math.random()}
                    onClick={(e) => handleMarker(e, quest)}
                  />

                  {/* AUSTIN */}
                  <Marker
                    width={50}
                    anchor={[30.267931537394215, -97.73583897893323]}
                    key={Math.random()}
                    onClick={(e) => handleMarker(e, quest)}
                  />

                  {/* TALENT, OR */}
                  <Marker
                    width={50}
                    anchor={[42.23982552166815, -122.77581022236774]}
                    key={Math.random()}
                    onClick={(e) => handleMarker(e, quest)}
                  />

                  {/* ASIA - makes the map center terribly so comment it out for now*/}

                  <Marker
                    width={50}
                    anchor={[33.84331290104348, 100.5644744159075]}
                    key={Math.random()}
                    onClick={(e) => handleMarker(e, quest)}
                  />

                  {isOverlayVisible && (
                    <Overlay anchor={overlay.anchor} offset={[35, 0]}>
                      <div className={styles.marker_container}>
                        <h1 className={styles.marker_container_heading}>
                          {overlay.quest && overlay.quest.quest_name}
                        </h1>
                        <p>
                          {overlay.quest && overlay.quest.quest_description}
                        </p>
                        <p>
                          deadline:{" "}
                          {overlay.quest &&
                            new Date(
                              overlay.quest?.quest_deadline || new Date()
                            ).toLocaleDateString()}
                        </p>
                      </div>
                    </Overlay>
                  )}
                </Map>
              </div>

              <div className={styles.picture_box_container}>
                <div className={styles.supplemental_picture_box}>
                  <img src="/assets/images/quest_detail_1.jpg" />
                </div>
                <div className={styles.supplemental_picture_box}>
                  <img src="/assets/images/quest_detail_2.jpg" />
                </div>
                <div className={styles.supplemental_picture_box}>
                  <img src="/assets/images/quest_detail_3.jpg" />
                </div>
                <div className={styles.supplemental_picture_box}>
                  <img src="/assets/images/quest_detail_4.jpg" />
                </div>
              </div>
            </div>
            {/* row 4 - leave no trace */}
            <div className={`${styles.content} ${styles.leave_no_trace}`}>
              <div className={styles.leave_no_trace_inner_container}>
                <img src="/assets/leave-no-trace-logo.svg" />
                <div className={styles.leave_no_trace_line} />
                <h5>
                  <span>
                    At Nobos, we recommend that all Adventurers follow the Leave
                    No Trace principles.
                  </span>
                  {` `}
                  <a href="#">You can Learn more about Leave No Trace here.</a>
                </h5>
              </div>
            </div>
            {/* row 5 - additional quest information */}
            <div className={styles.content}>
              <div className={styles.quest_details_dropdowns}>
                <h2>Additonal Quest Information</h2>

                <QuestDetailsDropdown title="QUALIFICATIONS">
                  <ul>
                    <li>Enthusiasm for hiking and environmental science</li>
                    <li>Ability to access forests or parks safely</li>
                    <li>
                      Willingness to collect data using a smartphone with
                      geolocation enabled
                    </li>
                  </ul>
                </QuestDetailsDropdown>

                <QuestDetailsDropdown title="EQUIPMENT">
                  {/* <div>{quest.equipment}</div> */}
                  <ul>
                    <li>
                      Cellphone with geolocation enabled and internet connection
                    </li>
                    <li>Measuring tape for tree circumference measurements</li>
                  </ul>
                </QuestDetailsDropdown>
                <QuestDetailsDropdown title="COMPENSATION">
                  {/* <div>{quest.compensation}</div> */}
                  <div>
                    This is a volunteer-based quest with no monetary
                    compensation. Your contribution helps build the Nobos
                    platform and supports global scientific research.
                  </div>
                </QuestDetailsDropdown>
                <QuestDetailsDropdown title="TRAINING">
                  <div>{quest.training_requirement}</div>
                </QuestDetailsDropdown>
                <QuestDetailsDropdown title="SAFETY INFORMATION">
                  <div>{quest.safety}</div>
                </QuestDetailsDropdown>
              </div>
            </div>
            {/* row 6 - Data Collection Details */}
            <div
              className={`${styles.content} ${styles.data_collection_details_container}`}>
              <div className={styles.data_collection_details_inner_container}>
                <h2>Data Collection Details</h2>
                <div className={styles.details}>
                  A simple process involving a phone and a measuring tape, with
                  data submission through the Nobos app.
                </div>
                <div className={styles.content}>
                  <div style={{ flex: "0 1 65%" }}>
                    <h4 className={styles.subhead}>PROCEDURE</h4>
                    <ul>
                      <li>
                        <div className={styles.title}>
                          Travel to Your Adventure Location
                        </div>
                        <div className={styles.content}>
                          Choose a nearby or distant outdoor location (forest,
                          park, or similar area) and travel there by walking,
                          running, cycling, or driving.
                        </div>
                      </li>
                      <li>
                        <div className={styles.title}>Begin Your Journey</div>
                        <div className={styles.content}>
                          Start your walk, hike, or run along the chosen path to
                          begin exploring the area.
                        </div>
                      </li>
                      <ul>
                        <li>
                          <div className={styles.title}>Find a Tree</div>
                          <div className={styles.content}>
                            Look for the first tree along your route to
                            photograph.
                          </div>
                        </li>
                        <li>
                          <div className={styles.title}>Open the Nobos App</div>
                          <div className={styles.content}>
                            Visit app.nobos.earth, log in to your account, and
                            navigate to the quest you applied for.
                          </div>
                        </li>
                        <li>
                          <div className={styles.title}>
                            Start Data Collection
                          </div>
                          <div className={styles.content}>
                            Begin the data collection process by selecting the
                            quest in the Nobos app and clicking “Submit Data.”
                          </div>
                        </li>
                        <li>
                          <div className={styles.title}>
                            Collect the Data Point
                          </div>
                          <div className={styles.content}>
                            Use a measuring tape to measure the circumference of
                            the tree, in centimeters, at arm&rsquo;s height.
                            Enter the measurement, in centimeters, into the app.
                            Take a photograph of the tree&rsquo;s bark using the
                            app. Ensure the app has permission to use your
                            location.( No need to take the picture including the
                            measuring tape, we just need the picture of the
                            bark) Upload the tree picture via the Nobos app
                            (geolocation data will be processed automatically in
                            the background).
                          </div>
                        </li>
                        <li>
                          <div className={styles.title}>
                            Continue Collecting Data
                          </div>
                          <div className={styles.content}>
                            Walk at least 10 yards (approximately 10 large
                            steps) to the next tree and repeat the data
                            collection process. Aim to collect data from at
                            least 20 trees in total.
                          </div>
                        </li>
                      </ul>
                      <li>
                        <div className={styles.title}>
                          Complete Data Submission
                        </div>
                        <div className={styles.content}>
                          After collecting data from at least 20 trees, submit
                          all data points through the Nobos app using the
                          “Complete Quest” button.
                        </div>
                      </li>
                      <li>
                        <div className={styles.title}>Enjoy Nature</div>
                        <div className={styles.content}>
                          Take time to appreciate your natural surroundings and
                          the adventure you&rsquo;ve just completed.
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div style={{ flex: "0 1 35%" }}>
                    <h4 className={styles.subhead}>DATA REQUIREMENTS</h4>
                    <div className={styles.data_requirements}>
                      Geolocated images of tree bark and tree circumference
                      measurements for at least 20 trees.
                    </div>
                    <h4 className={styles.subhead}>TYPE OF DATA</h4>
                    <div>photographs, geolocation, and species ID</div>
                  </div>
                </div>
              </div>
            </div>
            {/* row 7 - risk management */}
            <div className={`${styles.content} ${styles.risk_management}`}>
              <div className={styles.risk_management_inner_container}>
                <h2>Risk Management</h2>
                {/* <Markdown>{quest.safety}</Markdown> */}
                <div>
                  Participants should ensure they are in safe, stable areas for
                  hiking and tree selection. Avoid dangerous conditions like
                  poor weather, difficult terrain, or unstable ground.
                  It&rsquo;s important to maintain awareness of surroundings and
                  follow general hiking safety protocols.
                </div>
                <div style={{ fontWeight: 700 }}>
                  You can learn more about{" "}
                  <a href="#" style={{ color: "white" }}>
                    Nobos' Full Safety and Risk Policies here{" "}
                    <FaAngleRight size={10} />
                  </a>
                </div>
              </div>
            </div>
          </Suspense>
        </div>
      </main>
    </>
  );
};

export default QuestDetails;

export const questEquipmentSelector = (questType: string | undefined) => {
  switch (questType) {
    case "UNCATEGORIZED":
      return "UNCATEGORIZED";
    case "PHONE":
      return "/assets/images/icons/icon-phone.svg";
    case "GPS":
      return "/assets/images/icons/icon-gps.svg";
    case "BACKPACK":
      return "/assets/images/icons/icon-backpack.svg";
    case "COMPASS":
      return "/assets/images/icons/icon-compass.svg";
    default:
      return "UNCATEGORIZED";
  }
};

import styles from "./button.module.scss";

interface ButtonProps {
  children: React.ReactNode;
  callback?: () => void;
  type?: string;
  variant?: string;
  disabled?: boolean;
  rounded?: boolean;
}

export default function Button({
  children,
  callback,
  type = "submit",
  variant,
  disabled,
  rounded
}: ButtonProps) {
  const handleClick = () => {
    callback && callback();
  };

  return (
    <button
      className={`${styles.button} ${rounded && styles.rounded} ${variant && styles[`button--${variant}`]}`}
      onClick={() => handleClick()}
      disabled={disabled}
      type={type === "submit" ? "submit" : "button"}>
      {children}
    </button>
  );
}

import useSWR from "swr";
import useSWRMutation from 'swr/mutation';
import { postFetcher, putFetcher, fetcher } from '../fetcher';
import {
  CreateQuestSchemaType,
  EditQuestSchemaType
} from '../../admin/admin-components/CreateQuestForm/zod-objects';
import { quest } from "../../dto/interfaces";

async function sendEditRequest(
  url: string,
  { arg }: { arg: EditQuestSchemaType }
) {
  return putFetcher(`${url}${arg.quest_id}`, arg.quest_data);
}

async function sendCreateRequest(
  url: string,
  { arg }: { arg: CreateQuestSchemaType }
) {
  return postFetcher(url, arg);
}

interface UseQuestArgs {
  admin?: boolean;
}
export default function useQuest({ admin }: UseQuestArgs = {}) {
  // useSWR should not be needed since useSWRMutation returns data
  // but it does not trigger rerender
  // https://github.com/vercel/swr/issues/2267
  const { data, error: getQuestsError } = useSWR(
    `${process.env.REACT_APP_API_URL}/${admin ? 'admin/' : ''}quests/`,
    fetcher,
    {
      // revalidateOnFocus: false,
      // revalidateOnMount: false,
    }
  );

  const {
    trigger: editQuest,
    isMutating: editQuestIsMutating,
    data: editQuestData,
    error: editQuestError,
    reset: resetEditQuest
  } = useSWRMutation(
    `${process.env.REACT_APP_API_URL}/quests/`,
    (key: string, arg: { arg: EditQuestSchemaType }) =>
      sendEditRequest(key, arg),
    {
      onSuccess(data: quest, key, config) {
        // debugger;
      },
      onError(err, key, config) {
        // debugger;
        console.log(err);
      },
      throwOnError: false,
      populateCache: (result: quest, currentData?: quest[]) => {
        // https://swr.vercel.app/docs/mutation#update-cache-after-mutation
        const filteredQuests =
          currentData?.filter((quest) => quest.id !== result.id) || [];
        return [...filteredQuests, result];
      },
      revalidate: false
    }
  );

  const {
    trigger: createQuest,
    isMutating: createQuestIsMutating,
    data: createQuestData,
    error: createQuestError,
    reset: resetCreateQuest
  } = useSWRMutation(
    `${process.env.REACT_APP_API_URL}/quests/`,
    (key: string, arg: { arg: CreateQuestSchemaType }) =>
      sendCreateRequest(key, arg),
    {
      onSuccess(data: quest, key, config) {
        // debugger;
      },
      onError(err, key, config) {
        // debugger;
        console.log(err);
      },
      throwOnError: false,
      populateCache: (result: quest, currentData?: quest[]) => {
        const filteredQuests =
          currentData?.filter((quest) => quest.id !== result.id) || [];
        return [...filteredQuests, result];
      },
      revalidate: false
    }
  );

  return {
    editQuest,
    createQuest,
    quests: data,
    getQuestsError,
    editQuestIsMutating,
    editQuestData,
    editQuestError,
    createQuestIsMutating,
    createQuestData,
    createQuestError,
    resetEditQuest,
    resetCreateQuest
  };
}

import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

export async function fetchUserData() {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    const userSession = currentUser.getSignInUserSession();

    if (userSession) {
      const accessToken = userSession.getAccessToken().getJwtToken();
      localStorage.setItem('jwt', accessToken);
      return currentUser;
    }
  } catch (error) {
    console.error(`Error fetching user data: `, error);
  }
}

export async function handleSignIn() {
  try {
    await Auth.federatedSignIn();
  } catch (error) {
    console.log('Error signing in', error);
  }
}

export async function handleSignOut() {
  try {
    await Auth.signOut();

    localStorage.removeItem('jwt');
    console.log('No sign-in session');
  } catch (error) {
    console.error(`Error signing out: `, error);
  }
}

export function getFeatureTester(user: CognitoUser | null) {
  return user
    ?.getSignInUserSession()
    ?.getIdToken()
    .payload["cognito:groups"]?.includes("feature_testers") || false
}

export function getAdmin(user: CognitoUser | null) {
  return user
    ?.getSignInUserSession()
    ?.getIdToken()
    .payload["cognito:groups"]?.includes("admins") || false
}
import useQuest from "../../utils/hooks/use_quest";
import Header from '../../components/Header/Header';
import { useUserStore } from '../../stores/userStore';
import styles from "./samples.module.scss";
import { quest } from "../../dto/interfaces";
import { Link, Route, Switch, useParams } from "wouter";
import SampleDetail from "../admin-components/Samples/SampleDetail";
import { ErrorBoundary } from "react-error-boundary";
import { Suspense } from "react";
import { NestedRoutes } from "..";

export default function Samples() {
  const { quests } = useQuest({ admin: true });
  const params = useParams();

  const {
    authDetails,
    isAdmin
  } = useUserStore((state) => state);

  return (
    <>
      {!isAdmin ? <p>You're not authorized to view this page.</p> : <>
        <Header user={authDetails} showQuests={false} fullWidth />
        <NestedRoutes base="/admin_dashboard/samples">

          <div className={styles.container}>
            <h1>Samples</h1>
            <div className={styles.subContainer}>
              <div className={styles.samplesIndex}>
                <h3 className={styles.sectionTitle}>Pending Review</h3>
                <div className={styles.tableContainer}>
                  <table className={styles.table}>
                    <thead>
                      <tr>
                        <th className={styles.th} scope="col">
                          Quest
                        </th>
                        <th className={styles.th} scope="col">
                          Coordinates
                        </th>
                        <th className={styles.th} scope="col">
                          Click For Info
                        </th>
                      </tr>
                    </thead>
                    {Array.isArray(quests) && (
                      <tbody>
                        {quests.map((quest: quest) => {
                          if (!quest.quest_data?.length) {
                            return;
                          }

                          return quest.quest_data.map((questData, i) => {
                            return (
                              <tr key={i}>
                                <td>{quest.quest_name}</td>
                                <td>
                                  {questData.coordinates?.split(",")[0] || "-"},{" "}
                                  {questData.coordinates?.split(",")[1] || "-"}
                                </td>
                                <td>
                                  <Link href={`/${questData.id}`} replace>
                                    Link
                                  </Link>
                                </td>
                              </tr>
                            );
                          });
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              <div className={styles.sampleDetail}>
                <Switch>
                  <Route path=":id">
                    {params.id ? (
                      <ErrorBoundary
                        key={params.id}
                        fallback={
                          <p>
                            There was an error while attempting to fetch quest
                            details.
                          </p>
                        }
                      >
                        <Suspense>
                          <SampleDetail sampleId={Number(params.id)} />
                        </Suspense>
                      </ErrorBoundary>
                    ) : (
                      <h3 className={styles.sectionTitle}>Sample Detail</h3>
                    )}
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </NestedRoutes>
      </>}
    </>
  );
}

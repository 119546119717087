import styles from "./card.module.scss";

interface CardHeaderProps {
  children: React.ReactNode;
}

export default function CardHeader({ children }: CardHeaderProps) {
  return (
    <header
      className={styles.cardHeader}
    >
      {children}
    </header>
  );
}

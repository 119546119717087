import styles from "./input.module.scss";

interface ButtonProps {
  type?: string;
  placeholder?: string;
  callback?: () => void;
  disabled?: boolean;
}

export default function Input({
  type,
  placeholder,
  callback,
  disabled
}: ButtonProps) {
  const handleClick = () => {
    callback && callback();
  };

  return (
    <input
      className={styles.input}
      type={type || 'text'}
      placeholder={placeholder}
      onClick={() => handleClick()}
      disabled={disabled}
    />
  );
}

import useSWR from "swr";
import { Link } from "wouter";
import { BiCamera } from "react-icons/bi";
import { adventurer_quest } from "../dto/interfaces";
import { fetcher } from "../utils/fetcher";
import { ErrorBoundary } from "react-error-boundary";
import { useUserStore } from "../stores/userStore";
import Header from "../components/Header/Header";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader
} from "../components/Card/Card";
import styles from "./profile.module.scss";

export default function Profile(user: any) {
  const { authDetails } = useUserStore((state) => state);
  const username = authDetails?.getUsername();

  const { data: subscribedQuests } = useSWR<adventurer_quest[]>(
    username
      ? `${process.env.REACT_APP_API_URL}/adventurers/${username}/quests`
      : null,
    fetcher
  );

  return (
    <>
      <div className={styles.container}>
        <aside className={styles.profileContainer}>
          <img
            src="/assets/images/tree-pic1.jpg"
            className={styles['profile-picture']}
            alt="Profile"
          />
          <h2>Name</h2>
          <p>User details</p>
        </aside>

        <main className={styles['main-content']}>
          <Header user={authDetails} showLogo={false} />

          <h2>My Quests</h2>

          <div className={styles['active-quests']}>
            <ErrorBoundary fallback={<p>There was an error while attempting to fetch your quests.</p>}>
              {subscribedQuests &&
                subscribedQuests.map(
                  (subscribedQuest: adventurer_quest) =>
                    subscribedQuest.quest && subscribedQuest.status !== 'complete' && (
                      <Card key={Math.random()} image={subscribedQuest.quest.quest_image && subscribedQuest.quest.quest_image} orientation='row' imageHeight='94px' imageWidth='94px'>
                        <CardHeader><Link href={`/quests/${subscribedQuest.quest.id}`}>{subscribedQuest.quest.quest_name}</Link></CardHeader>
                        <CardContent>
                          <div style={{ display: 'flex', alignItems: 'flex-end', columnGap: '6px', justifyContent: 'space-between' }}>
                            <div>{subscribedQuest.quest.locations && `${subscribedQuest.quest.locations[0].locality}, ${subscribedQuest.quest.locations[0].administrative_area}`}</div> <div>{subscribedQuest.quest.quest_deadline ? new Date(subscribedQuest.quest.quest_deadline).toLocaleDateString() : 'none'}</div>
                          </div>
                        </CardContent>
                        <CardFooter>
                          <div style={{ display: 'flex', alignItems: 'center', columnGap: '6px' }}>
                            <BiCamera size={24} /> <span>Photography</span>
                          </div>
                        </CardFooter>
                      </Card>
                    )
                )}
            </ErrorBoundary>
          </div>

          {/* <h2>Completed Quests</h2>

          <div className={styles['completed-quests']}>
            <ErrorBoundary fallback={<p>There was an error while attempting to fetch your quests.</p>}>
              {subscribedQuests &&
                subscribedQuests.map(
                  (subscribedQuest: adventurer_quest) =>
                    subscribedQuest.quest && subscribedQuest.status === 'complete' && (
                      <QuestBlock
                        questData={subscribedQuest.quest}
                        key={Math.random()}
                      />
                    )
                )}
            </ErrorBoundary>
          </div> */}
        </main>
      </div>
    </>
  );
}

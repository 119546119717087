import type { quest } from "../../../dto/interfaces";
import styles from "./create-quest-form.module.scss";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  createQuestSchema,
  CreateQuestSchemaType,
  EditQuestSchemaType
} from "./zod-objects";
import { useEffect, useState } from "react";
import useQuest from "../../../utils/hooks/use_quest";
import { getDefaultValues } from "./default-values";
import useOrganization from "../../../utils/hooks/use_organization";
import { LocationSection } from "./location-sub-form";
import useQuestLocation from "../../../utils/hooks/use_quest_location";
import { Quest_StatusSchema, Quest_TypeSchema } from "../../../dto/zod";
import Button from "../../../components/Button/Button";

interface CreateQuestFormProps {
  quest?: quest;
}

export default function CreateQuestForm({ quest }: CreateQuestFormProps) {
  const [existingQuest, setExistingQuest] = useState<quest | null>(
    quest || null
  );

  const [deletedLocations, setDeletedLocations] = useState<number[]>([]);
  const recordDeletedLocation = (locationIndex: number) => {
    const deletedLocationId = getValues().locations[locationIndex].id;
    if (!deletedLocationId) {
      return;
    }
    setDeletedLocations([...deletedLocations, deletedLocationId]);
  };

  // init form
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset
  } = useForm<CreateQuestSchemaType>({
    defaultValues: getDefaultValues(existingQuest || undefined),
    resolver: zodResolver(createQuestSchema)
  });

  // init swr api hooks
  const { organizations } = useOrganization();

  // set organization id on form init
  useEffect(() => {
    existingQuest?.organization?.id &&
      setValue("organization.organization_id", existingQuest?.organization?.id);
  }, [organizations, existingQuest, setValue]);

  const {
    editQuest,
    createQuest,
    editQuestIsMutating,
    editQuestData,
    editQuestError,
    createQuestIsMutating,
    createQuestData,
    createQuestError,
    resetCreateQuest,
    resetEditQuest
  } = useQuest();

  const {
    deleteQuestLocations,
    deleteQuestLocationsIsMutating,
    deleteQuestLocationsError
  } = useQuestLocation();

  // define form submit function
  const onSubmit = (data: CreateQuestSchemaType) => {
    switch (!!existingQuest) {
      case true:
        resetEditQuest();
        if (deletedLocations.length > 0) {
          deleteQuestLocations(deletedLocations)
            .then((val) => {
              console.log(val);
            })
            .catch((e) => console.log(e));
        }
        editQuest({
          quest_data: { ...data, deleted_locations: deletedLocations },
          quest_id: existingQuest!.id // why need the ! if !! above
        } as EditQuestSchemaType)
          .then((editedQuest) => {
            reset(getDefaultValues(editedQuest));
          })
          .catch((e) => console.log(e));
        break;
      case false:
        resetCreateQuest();
        createQuest(data as CreateQuestSchemaType)
          .then((createdQuest) => {
            reset(getDefaultValues(createdQuest));
            createdQuest && setExistingQuest(createdQuest);
          })
          .catch((e) => console.log(e));
        break;
    }
  };

  // for dev
  // useEffect(() => {
  //   console.log(errors);
  //   console.log(getValues());
  // }, [errors, getValues]);

  return (
    <div
      style={{
        width: "1200px",
        maxWidth: "100%",
        height: "80vh",
        overflowY: "scroll"
      }}>
      <div className={styles.container}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.formItem}>
            <label htmlFor="quest_name">Quest Name</label>
            <input {...register("quest_name")} />
            <p>{errors.quest_name?.message}</p>
          </div>

          {/* QUEST TYPE */}
          <div className={styles.formSubsection}>
            <div className={styles.formSubsectionItemWithError}>
              <div className={styles.formSubsectionItem}>
                <label htmlFor="quest_type">Quest Type</label>
                <select {...register("quest_type", {})}>
                  <>
                    {Quest_TypeSchema.options.map((type, i) => (
                      <option value={type} key={i}>
                        {type}
                      </option>
                    ))}
                  </>
                </select>
              </div>
              <p>{errors.organization?.organization_id?.message}</p>
            </div>
          </div>

          <label htmlFor="quest_status">Quest Status</label>

          <div style={{ display: "flex" }}>
            <>
              {Quest_StatusSchema.options.map((status) => (
                <div
                  key={status}
                  className={styles.formItem}
                  style={{ display: "flex", flexDirection: "row" }}>
                  <input
                    type="radio"
                    {...register("quest_status")}
                    value={status}
                  />
                  <label htmlFor="quest_status" style={{ marginLeft: "5px" }}>
                    {status}
                  </label>
                </div>
              ))}
            </>
          </div>

          {errors.quest_status?.message && (
            <p>{errors.quest_status?.message}</p>
          )}

          <div className={styles.formItem}>
            <label htmlFor="quest_description">Quest Description</label>
            <textarea {...register("quest_description")} />
            <p>{errors.quest_description?.message}</p>
          </div>

          {/* ORGANIZATION */}
          <div className={styles.formSubsection}>
            <h5>Organization</h5>

            <div className={styles.formSubsectionItemWithError}>
              <div className={styles.formSubsectionItem}>
                <label htmlFor="organization.organization_id">
                  Select Organization
                </label>
                <select
                  {...register("organization.organization_id", {
                    setValueAs: (v) => {
                      return !!v ? parseInt(v) : null;
                    }
                  })}>
                  {organizations && (
                    <>
                      <option value={undefined}>---</option>
                      {Array.isArray(organizations) &&
                        organizations.map((org) => (
                          <option value={org.id} key={org.id}>
                            {org.organization_name}
                          </option>
                        ))}
                    </>
                  )}
                </select>
              </div>
              <p>{errors.organization?.organization_id?.message}</p>
            </div>
          </div>

          <LocationSection
            register={register}
            errors={errors}
            recordDeletedLocation={recordDeletedLocation}
            control={control}
          />

          <div className={`${styles.formItem} ${styles.half}`}>
            <label htmlFor="quest_deadline">deadline</label>
            <input
              {...register("quest_deadline", {
                valueAsDate: true
              })}
              type="date"
            />
            <p>{errors.quest_deadline?.message}</p>
          </div>

          <div className={`${styles.formItem} ${styles.half}`}>
            <label htmlFor="adventurer_limit">adventurer limit</label>
            <input
              {...register("adventurer_limit", {
                valueAsNumber: true
              })}
              type="number"
            />
            <p>{errors.adventurer_limit?.message}</p>
          </div>

          <div className={`${styles.formItem}`}>
            <label htmlFor="equipment">equipment</label>
            <textarea {...register("equipment")} />
            <p>{errors.equipment?.message}</p>
          </div>

          <div className={`${styles.formItem}`}>
            <label htmlFor="quest_difficulty">quest difficulty</label>
            <input
              {...register("quest_difficulty", {
                valueAsNumber: true
              })}
              type="number"
              max="5"
              min="1"
            />
            <p>{errors.quest_difficulty?.message}</p>
          </div>

          <div className={`${styles.formItem}`}>
            <label htmlFor="safety">safety</label>
            <textarea {...register("safety")} />
            <p>{errors.safety?.message}</p>
          </div>

          <div className={`${styles.formItem}`}>
            <label htmlFor="study_motivation">study motivation</label>
            <textarea {...register("study_motivation")} />
            <p>{errors.study_motivation?.message}</p>
          </div>

          <div className={`${styles.formItem}`}>
            <label htmlFor="training_requirement">training_requirement</label>
            <textarea {...register("training_requirement")} />
            <p>{errors.training_requirement?.message}</p>
          </div>

          <Button
            type="submit"
            disabled={editQuestIsMutating || createQuestIsMutating}>
            submit
          </Button>

          {/* ERRORS */}
          {errors.root?.organization_null && (
            <p style={{ textAlign: "left" }}>
              {errors.root.organization_null.message}
            </p>
          )}

          {deleteQuestLocationsError && (
            <p style={{ textAlign: "left" }}>Error deleting quest locations</p>
          )}

          {editQuestError && (
            <p style={{ textAlign: "left" }}>Error editing quest</p>
          )}
          {editQuestData && (
            <p style={{ textAlign: "left" }} className={styles.success}>
              Quest edited successfully
            </p>
          )}

          {createQuestError && (
            <p style={{ textAlign: "left" }}>Error creating quest</p>
          )}
          {createQuestData && (
            <p style={{ textAlign: "left" }} className={styles.success}>
              Quest created successfully
            </p>
          )}
        </form>
      </div>
    </div>
  );
}

export const questProperty = (propertyName: string) => {
  switch (propertyName) {
    case "UNCATEGORIZED":
      return {
        label: "Unknown",
        units: ""
      };
    case "tree_width":
      return {
        label: "Width",
        units: "cm"
      };
    // case 'tree_height':
    //   return {
    //     label: 'Height',
    //     units: 'M'
    //   };
    case "user_supplied_latitude":
      return {
        label: "Latitude",
        units: ""
      };
    case "user_supplied_longitude":
      return {
        label: "Longitude",
        units: ""
      };
    default:
      return {
        label: "Unknown",
        units: ""
      };
  }
};

import { useEffect } from "react";
import { Amplify } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import awsconfig from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import { Switch, Route } from "wouter";
import { useUserStore } from "./stores/userStore";
import Home from "./home/Home";
import QuestDetails from "./quests/QuestDetails";
import QuestData from "./quests/QuestData";
import Profile from "./profile/Profile";
import TestGps from "./test_gps/TestGps";
import TestWebAppPhoneAndPlantId from "./test_web_app_phone_and_plant_id/TestWebAppPhoneAndPlantId";
import { fetchUserData, getFeatureTester, getAdmin } from "./utils/userAuth";
import "./globals.scss";
import "./App.scss";
import AdminHome from "./admin/admin-home/AdminHome";
import Applications from "./admin/applications/Applications";
import Datasets from "./admin/datasets/Datasets";
import FeatureTesting from "./admin/feature-testing/FeatureTesting";
import Samples from "./admin/samples/Samples";
import Settings from "./admin/settings/Settings";

awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;

Amplify.configure(awsconfig);

export function App() {
  const {
    isFeatureTester,
    updateCognitoUser,
    setIsAdmin,
    setIsFeatureTester,
    setIsLoggedIn
  } = useUserStore((state) => state);

  useEffect(() => {
    fetchUserData().then((result) => {
      if (result) {
        updateCognitoUser(result);
        setIsAdmin(getAdmin(result));
        setIsFeatureTester(getFeatureTester(result));
        setIsLoggedIn(result || false);
      }
    });
  }, [updateCognitoUser]);

  return (
    <>
      <div className="app-container">
        <Switch>
          <Route path="/">{(params) => <Home />}</Route>
          <Route path="/quests/:id">{(params) => <QuestDetails />}</Route>
          <Route path="/quests/:id/data">{(params) => <QuestData />}</Route>
          <Route path="/profile" component={Profile} />
          <Route path="/test_gps">
            <TestGps isFeatureTester={isFeatureTester} />
          </Route>

          <Route path="/test_web_app_phone_and_plant_id">
            <TestWebAppPhoneAndPlantId isFeatureTester={isFeatureTester} />
          </Route>

          <Route path="/admin_dashboard" component={AdminHome} />
          <Route path="admin_dashboard/applications" component={Applications} />
          <Route path="admin_dashboard/datasets" component={Datasets} />
          <Route path="admin_dashboard/samples/:id?">
            <Samples />
          </Route>
          <Route path="admin_dashboard/settings" component={Settings} />
          <Route
            path="admin_dashboard/feature-testing"
            component={FeatureTesting}
          />

          {/* Default route in a switch */}
          <Route>404: No such page here!</Route>
          {/* Default route in a switch */}
          <Route>404: No such page!</Route>
        </Switch>

        <footer>
          <div>&copy; 2024 Nobos Adventure</div>
        </footer>
      </div>
    </>
  );
}

export default App;

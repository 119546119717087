import { create } from 'zustand';
import { CognitoUser } from "amazon-cognito-identity-js";

type State = {
  authDetails: CognitoUser | null;
  isLoggedIn: boolean;
  isAdmin: boolean;
  isFeatureTester: boolean;
};

type Action = {
  updateCognitoUser: (authDetails: State['authDetails']) => void;
  setIsLoggedIn: (authDetails: State['isAdmin']) => void;
  setIsAdmin: (authDetails: State['isAdmin']) => void;
  setIsFeatureTester: (authDetails: State['isFeatureTester']) => void;
};

// Create your store, which includes both state and (optionally) actions
export const useUserStore = create<State & Action>((set) => ({
  authDetails: null,
  userDetails: {},
  isLoggedIn: false,
  isAdmin: false,
  isFeatureTester: false,
  updateCognitoUser: (authDetails: CognitoUser | null) =>
    set(() => ({ authDetails: authDetails })),
  setIsLoggedIn: (isLoggedIn: boolean) => set(() => ({ isLoggedIn: isLoggedIn })),
  setIsAdmin: (isAdmin: boolean) => set(() => ({ isAdmin: isAdmin })),
  setIsFeatureTester: (isFeatureTester: boolean) =>
    set(() => ({ isFeatureTester: isFeatureTester }))
}));

import { Link } from "wouter";
import { quest } from "../dto/interfaces";
import { BiCamera } from "react-icons/bi";
import { Card, CardHeader, CardContent, CardFooter } from '../components/Card/Card';

interface QuestBlockProps {
    orientation?: string;
    width?: number;
    questData: quest;
}

export default function QuestBlock({ orientation, width, questData }: QuestBlockProps) {
    const deadline = questData.quest_deadline ? new Date(questData.quest_deadline).toLocaleDateString() : 'none';

    return (
        <Card image={questData.quest_image && questData.quest_image} orientation={orientation}>
            <CardHeader><Link href={`/quests/${questData.id}`}>{questData.quest_name}</Link></CardHeader>
            <CardContent>
                <div style={{ display: 'flex', alignItems: 'flex-end', columnGap: '6px', justifyContent: 'space-between' }}>
                    <div>{questData.locations && `${questData.locations[0].locality}, ${questData.locations[0].administrative_area}`}</div> <div>{deadline}</div>
                </div>
            </CardContent>
            <CardFooter>
                <div style={{ display: 'flex', alignItems: 'center', columnGap: '6px' }}>
                    <BiCamera size={24} /> <span>Photography</span>
                </div>
            </CardFooter>
        </Card>
    );
}

import Header from '../../components/Header/Header';
import { useUserStore } from '../../stores/userStore';
import styles from "./applications.module.css";

export default function Applications() {
  const {
    authDetails,
    isAdmin
  } = useUserStore((state) => state);

  return <div>
    {!isAdmin ? <p>You're not authorized to view this page.</p> : <>
      <Header user={authDetails} showQuests={false} fullWidth />
      <h1>Applications</h1>
    </>}
  </div>;
}

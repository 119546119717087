export const questTypeSelector = (questType: string) => {
  switch (questType) {
    case 'UNCATEGORIZED':
      return 'UNCATEGORIZED';
    case 'TREES':
      return '/assets/images/icons/icon-trees.svg';
    case 'WATER':
      return '/assets/images/icons/icon-waves.svg';
    case 'SOIL':
      return '/assets/images/icons/icon-soil.svg';
    case 'AIR':
      return '/assets/images/icons/icon-air.svg';
    case 'HIKING':
      return '/assets/images/icons/icon-hiker.svg';
    case 'DIVING':
      return '/assets/images/icons/icon-diver.svg';
    case 'PADDLING':
      return '/assets/images/icons/icon-kayak.svg';
    case 'BOATING':
      return '/assets/images/icons/icon-trees.boat';
    default:
      return 'UNCATEGORIZED';
  }
}